html,
body {
  height: 100%;
}
body {
  margin: 0;
  line-height: 2;
  font: 400 14px/24px "Merriweather", serif;
  color: #aeaeae;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #424241;
  margin: 0 0 20px;
  line-height: normal;
  font-weight: 700;
}
* {
  outline: none !important;
  -webkit-overflow-scrolling: touch;
}
a {
  text-decoration: unset !important;
}
ngb-rating {
  color: #FFC107;
  font-size: 20px;
}
.error-border {
  border: 1px solid #a94442;
}
.has-error {
  color: #a94442;
}
.text-success {
  color: green;
}
img {
  max-width: 100%;
}
.ssr-not-loaded {
  display: none !important;
}
.sf-menu .nav-item:first-child .nav-link {
  border-left: none;
}
.sf-menu .nav-item {
  padding: 20px 0;
}
.footer-menu {
  background-color: #2A2A2C !important;
  font-size: 12px;
  color: white !important;
}
.footer-product-img {
  border: 4px solid #444;
  margin-right: 20px;
}
.color-link {
  color: #ead083 !important;
}
.date-text {
  color: #424241;
  font-weight: bold;
}
/*breadcrumb*/
.breadcrumb {
  margin: 15px 0;
  padding: 13px 15px;
  border-radius: 0;
  background: #fff;
  font-size: 14px;
  line-height: 24px;
}
.breadcrumb > li a {
  color: #aeaeae !important;
}
/*List images*/
.breadcrumb > li + li:before {
  content: "\f06e";
  padding: 0;
  font-family: "material-design";
  font-size: 20px;
  line-height: 20px;
}
.brown-background {
  z-index: 7;
  content: '';
  background: #a37e47;
  height: 7px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.product-thumb {
  position: relative;
  box-sizing: border-box;
  text-align: center;
}
.product-thumb .image {
  position: relative;
  background: #fff;
  padding-bottom: 10px;
}
.product-thumb .image > a img {
  transition: 2.6s all ease;
  margin: 0 auto;
  padding: 40px 20px 30px;
}
.carousel-inner > .item > a > img,
.carousel-inner > .item > img,
.img-responsive,
.thumbnail a > img,
.thumbnail > img {
  display: block;
  max-width: 100%;
  height: auto;
}
.product-thumb .image > a:before {
  z-index: 7;
  content: '';
  /* background: #a37e47; */
  height: 7px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.product-thumb .image > .quickview {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: inline-block;
  vertical-align: middle;
  opacity: 0;
  padding: 12px 12px;
  border-radius: 50%;
  /* background: #a37e47; */
  color: #fff;
  z-index: 13;
  font-size: 24px;
}
.product-thumb:hover .image > .quickview {
  opacity: 1;
}
.quickview:hover {
  background-color: #1b1e21;
  opacity: 0.3;
  border-radius: 50%;
}
/*Search*/
.newsletter-popup-btn {
  margin-top: 0;
  border: none;
  outline: none;
  display: inline-block;
  line-height: 30px;
  height: 48px;
  color: #FFF;
  background: #a37e47;
  border-radius: 0;
  padding: 9px 30px 18px;
  box-sizing: border-box;
  box-shadow: none;
}
.btn-over-slider {
  top: 110px;
  left: 30px;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(17, 17, 17, 0.88);
  position: absolute;
  text-align: center;
  display: block;
  z-index: 120;
}
.btn-over-slider span {
  color: #ccc;
}
.btn-over-slider-btn {
  font-size: 14px;
  color: #FFF;
  background: #424241 !important;
  text-shadow: none;
  border-radius: 0;
  border: none;
  padding: 12px 16px;
  outline: none;
}
.navbar-toggler {
  border: 0 !important;
  color: #000;
}
.blog-date,
.blog-content {
  display: inline-block;
}
.blog-date {
  width: 100px;
  height: 100%;
  background-color: #f4f3f6;
  padding: 20px 15px;
  text-align: center;
}
.blog-date p:first-child {
  color: #d4d4d4;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  border-bottom: 1px solid #d4d4d4;
}
.blog-date {
  width: 100px;
  height: 100%;
  background-color: #f4f3f6;
  padding: 20px 15px;
  text-align: center;
}
.blog-message {
  height: 200px;
  margin: 15px 0;
  padding-left: 0;
  padding-right: 30px;
}
.blog-message:hover .blog-date {
  background-color: orangered;
  color: white !important;
}
.contact_page .fa {
  position: relative;
  padding-left: 70px;
  min-height: 55px;
  display: block;
}
.material-design-phone370:before {
  content: "\f00d";
}
.contact_page .fa:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  color: #a37e47;
  border-radius: 50%;
  border: 2px solid #a37e47;
  font-size: 26px;
}
.fltrs-wrppr input {
  margin-bottom: 10px;
}
.filter-ul {
  list-style: none;
  max-height: 185px;
  overflow-y: auto;
  margin-bottom: 15px;
  -webkit-margin-before: 0rem !important;
          margin-block-start: 0rem !important;
  margin-top: 10px;
}
.p-virtualscroller-item {
  height: auto !important;
  width: 100% !important;
}
.fltrs-wrppr .filter-ul::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 100px !important;
}
.fltrs-wrppr .filter-ul::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 100px;
}
@media (max-width: 768px) {
  .bg-menu {
    background-color: white !important;
  }
  .sf-menu .nav-item > .nav-link {
    display: block;
    color: #2A2A2C !important;
  }
  .footer-menu .nav-item > .nav-link {
    color: white !important;
  }
  .nav-link {
    color: #2A2A2C !important;
  }
}
.panel {
  padding-top: 15px;
  background-color: transparent;
  border: none;
  border-top: 2px solid #a37e47;
  box-shadow: none;
}
@media (min-width: 768px) {
  .bg-menu {
    background-color: #2A2A2C !important;
  }
  .nav-link {
    color: white !important;
  }
  .sf-menu .nav-item > .nav-link {
    display: block;
    font-size: 12px;
    line-height: 34px;
    letter-spacing: 1px;
    padding: 0 19px;
    border-right: 0.5px solid rgba(255, 255, 255, 0.2);
    border-left: 0.5px solid rgba(255, 255, 255, 0.2);
    color: #fff;
  }
  .sf-menu .nav-item:first-child .nav-link {
    border-left: none !important;
  }
  .sf-menu .nav-item:last-child .nav-link {
    border-right: none;
  }
  .sf-menu .nav-item:hover {
    border-bottom: 1px solid #ead083 !important;
  }
  .nav-item .nav-link:hover {
    color: #ead083 !important;
  }
}
